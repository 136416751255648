export const AUTH_HOST = "https://weblogin.asu.edu/cas";

export const MODEL_OPTIONS = [
  // {
  //   name: "Titan Lite (4k)",
  //   key: "titang1lite",
  //   provider: "aws",
  // },
  // {
  //   name: "Titan Express (8k)",
  //   key: "titang1express",
  //   provider: "aws",
  // },
  {
    name: "Claude 2.1",
    key: "claude2_1",
    provider: "aws",
  },
  {
    name: "Claude 2",
    key: "claude2",
    provider: "aws",
  },
  {
    name: "Claude Instant",
    key: "claudeinstant",
    provider: "aws",
  },
  // {
  //   name: "Command",
  //   key: "command",
  //   provider: "aws",
  // },
  // {
  //   name: "Command Light",
  //   key: "commandlight",
  //   provider: "aws",
  // },
  {
    name: "GPT 3.5",
    key: "gpt3_5",
    provider: "openai",
  },
  {
    name: "GPT 3.5 (16k)",
    key: "gpt3_5-16k",
    provider: "openai",
  },
  {
    name: "GPT 4",
    key: "gpt4",
    provider: "openai",
  },
  {
    name: "GPT 4 (32k)",
    key: "gpt4-32k",
    provider: "openai",
  },
  {
    name: "GPT 4 Turbo",
    key: "gpt4turbo",
    provider: "openai",
  },
  {
    name: "GPT 4o",
    key: "gpt4o",
    provider: "openai",
  },
  {
    name: "GPT 4o Mini",
    key: "gpt4o_mini",
    provider: "openai",
  },
  {
    name: "O1 Mini",
    key: "o1-mini",
    provider: "openai",
  },
  {
    name: "O1",
    key: "o1",
    provider: "openai",
  },
  {
    name: "Gemini Pro",
    key: "geminipro",
    provider: "gcp-deepmind",
  },
  {
    name: "Gemini Pro 1.5",
    key: "geminipro1_5",
    provider: "gcp-deepmind",
  },
  {
    name: "Gemini Flash 1.5",
    key: "geminiflash1_5",
    provider: "gcp-deepmind",
  },
  // {
  //   name: "J2 Ultra",
  //   key: "j2ultra",
  //   provider: "aws",
  // },
  // {
  //   name: "J2 Mid",
  //   key: "j2mid",
  //   provider: "aws",
  // },
  {
    name: "Claude 3 Opus",
    key: "claude3_opus",
    provider: "aws",
  },
  {
    name: "Claude 3 Sonnet",
    key: "claude3_sonnet",
    provider: "aws",
  },
  {
    name: "Claude 3.5 Sonnet",
    key: "claude3_5_sonnet",
    provider: "aws",
  },
  {
    name: "Claude 3 Haiku",
    key: "claude3_haiku",
    provider: "aws",
  },
  {
    name: "Llama3 8b",
    key: "llama3-8b",
    provider: "aws",
  },
  {
    name: "Llama3 70b",
    key: "llama3-70b",
    provider: "aws",
  },
  {
    name: "Llama3 405b",
    key: "llama3-405b",
    provider: "aws",
  },
  {
    name: "Mistral 7b",
    key: "mistral-7b",
    provider: "aws",
  },
  {
    name: "Mistral 8x7b",
    key: "mistral-8x7b",
    provider: "aws",
  },
  {
    name: "Mistral Large",
    key: "mistral-large",
    provider: "aws",
  },
];
